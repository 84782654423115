import React from 'react'
import './works.css'
import video1 from "./img/pottery/other/horse2.MOV";

function Videos() {


    const leftTitle = (
        <div className="left-title">
            <div>
                <a href="/works/plates" className="whiteLink">| Plates</a>
            </div>
            <br />
            <div>
                <a href="/works/bowls" className="whiteLink">| Bowls</a>
            </div>
            <br />
            <div>
                <a href="/works/cups" className="whiteLink">| Cups</a>
            </div>
            <br />
            <div>
                <a href="/works/decor" className="whiteLink">| Decor</a>
            </div>
            <br />
            <div>
                <a href="/works/vases" className="whiteLink">| Vases</a>
            </div>
            <br />
            <div>
                <a href="/works/teapots" className="whiteLink">| Teapots</a>
            </div>
            <br />
            <div>
                <a href="/works/videos" className="yellowLink">| Videos</a>
            </div>
            <br />
            <div>
                <a href="/works/other" className="whiteLink">| Other</a>
            </div>
        </div>
    );

    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    Works
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="categories">
                    <div className="category-boxing" id="videoSection">
                        {leftTitle}
                        <div className="category-flex">
                            <div className="category-title">
                                Videos
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="videos">
                                <video controls autoPlay loop muted className="video">
                                    <source src={video1} type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Videos
