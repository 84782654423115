import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home'
import Navigation from './components/Navigation'
import About from './components/About'
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop'
import Bowls from './components/Bowls'
import Cups from './components/Cups'
import Plates from './components/Plates'
import Decor from './components/Decor'
import Vases from './components/Vases'
import Teapots from './components/Teapots'
import Videos from './components/Videos'
import Other from './components/Other'

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Navigation />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" component={About} />
              <Route path="/works" exact component={Plates} />
              <Route path="/works/bowls" component={Bowls} />
              <Route path="/works/cups" component={Cups} />
              <Route path="/works/plates" component={Plates} />
              <Route path="/works/decor" component={Decor} />
              <Route path="/works/vases" component={Vases} />
              <Route path="/works/teapots" component={Teapots} />
              <Route path="/works/videos" component={Videos} />
              <Route path="/works/other" component={Other} />
            </Switch>
          <Contact />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
