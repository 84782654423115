import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './navigation.css'

// window.onscroll = function () { Navigation() };
function Navigation() {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        console.log(window.pageYOffset)
        if (window.pageYOffset >= 20) {
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeBackground);

    const location = useLocation();
    console.log("Navigation", location);

    const rootClass = (location.pathname == "/") ? 'specialColor' : 'page';
    const aboutClass = (location.pathname == "/about") ? 'specialColor' : 'page';
    const worksClass = location.pathname.includes("/works") ? 'specialColor' : 'page';

    console.log("Special Class List -->", "root:", rootClass, ",about:", aboutClass, ",works:", worksClass);

    return (
        <div >
            <div className={navbar ? 'nav-container' : 'nav-transparent'}>
                <div className="brandName">
                    <Link to="/" className="specialColor">
                        Chialing Hsieh
                    </Link>
                </div>
                <ul className="pages">
                    <Link to="/" className={rootClass}>
                        <li>Home</li>
                    </Link>
                    <Link to="/about" className={aboutClass}>
                        <li>About</li>
                    </Link>
                    <Link to="/works" className={worksClass}>
                        <li>Works</li>
                    </Link>
                    {/* <Link to="/cart" className="page">
                        <li>Cart</li>
                    </Link> */}
                </ul>
            </div>
        </div>
    )
}

export default Navigation
